import { apiGateway } from "./apiURLs";
import { get } from "./commonApi";
import { onPrem } from "./helperMethods";

export const loanCSRFQueryUrl = `/api/loanapp/CheckCSRF`;
export const umiCSRFQueryUrl = `/umi/CheckCSRF`;
export const taxonomyCSRFQueryUrl = `/api/Taxonomy/CheckCSRF`;
export const dealerCSRFQueryUrl = `/api/dealer/CheckCSRF`;
export const analyticsCSRFQueryUrl = `/api/analytic/CheckCSRF`;
export const fedExCSRFQueryUrl = `/api/fedex/CheckCSRF`;
export const dashboardCSRFQueryUrl = `/api/dashboard/CheckCSRF`;

export const umiCookie = `D74F2BA84D4A4959AAC7836F596A4EEF`;
export const loanCookie = `A71DA7204CFA4B9288A6A6B5D43D5538`;
export const taxonomyCookie = `66E219F1C3D64D838E242FA965D278FA`;
export const dealerCookie = `F3BDF3D7EA7B4551AA2F616190B98160`;
export const analyticsCookie = `2966D5A36C4C4177A65531C3B19CC8FE`;
export const fedExCookie = `C0217846444242E7A0EAD44E856E2B67`;
export const dashboardCookie = `8AA1BD99D9504419A998D018E5E7609C`;

export const CheckCSRF = async (queryUrl: string) => {
  if (onPrem) {
    const res = await get(apiGateway, queryUrl, false, "");
    return res;
  }
};

export const getCookie = (name: string) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if this cookie starts with the provided name
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  checkAllCSRF();
};

export const checkAllCSRF = () => {
  if (onPrem) {
    CheckCSRF(analyticsCSRFQueryUrl);
    CheckCSRF(loanCSRFQueryUrl);
    CheckCSRF(umiCSRFQueryUrl);
    CheckCSRF(dealerCSRFQueryUrl);
    CheckCSRF(taxonomyCSRFQueryUrl);
    CheckCSRF(fedExCSRFQueryUrl);
    CheckCSRF(dashboardCSRFQueryUrl);
  }
};
