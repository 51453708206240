import React, { useState, useEffect, Fragment } from "react";
import { useLocation } from "react-router";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { checkIsActive } from "../../../../_helpers";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import {
  clearStorage,
  setStorage,
  getStorage,
} from "../../../../../app/common/storageHelper";
import {
  dealerDetails,
  selectedLoanType,
  dealerIdURL,
  enumLoanStatus,
  loanAppId,
  progressBar,
  userActiveTabKey,
  dataTblPgLen,
  defPgLen,
  verosEmployee,
  dealerEmployee,
  dealerAdmin,
  verosAdmin,
  superAdmin,
  readonly,
  enumDataTableTabs,
  enumMenuTabs,
  sinLnType,
  jntLnType,
  defaultPageNumber,
  defaultSortColumn,
  dtSortOrder,
  getContractStates,
} from "../../../../../app/common/helperContainer";
import {
  globalSaveAnalytics,
  setPageLength,
  checkAndUpdateToken,
  resetDataTableValues,
  DefaultMenuTabRoute,
  useCommonServices,
  useAddLoanServices,
  useAdminServices,
  useDashboardServices,
} from "../../../../../app/common/helperMethods";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IAllAppsCount } from "../../../../../app/modules/admin/adminModel";
import IdleTimerContainer from "../../../../../app/common/idleTimer/idleTimerContainer";
import ClearDefaultRoute from "../../../../../app/common/clearDefaultRoute/clearDefaultRoute";
import { getMessages } from "../../../../../app/common/messageContainer";
import RefreshTimer from "../../../../../app/common/idleTimer/refreshTimer";

const AsideMenuList = React.memo((props: any) => {
  const history = useHistory();
  const { user } = useSelector((state: any) => state.auth);
  const { getDealersById } = useCommonServices();
  const { changesStatus, getRetailerLoanOnPageLoad } = useAddLoanServices();
  const { getAllLoanAppsCount, getSearchDealers, getEmpHierDlrSearch } =
    useAdminServices();
  const { getDashboardDetNoRet } = useDashboardServices();
  const location = useLocation();
  const typeHead: any[] | (() => any[]) = [];
  const [options, setOptions] = useState(typeHead);
  const [isLoading, setIsLoading] = useState(false);
  const defCount: IAllAppsCount = {
    AllApps: 0,
    UnderWriting: 0,
    Funding: 0,
    Funded: 0,
    Booked: 0,
  };
  const [appsCount, setAppsCount] = useState(defCount);
  const [, setselectedDealer] = useState("");
  const [show, setShow] = useState(false);
  const [showIsExist, setShowIsExist] = useState(false);
  const handleCloseIsExist = () => setShowIsExist(false);
  const handleIsExistClose = () => setShow(false);
  const [isDbdAvailable, setIsDbdAvailable] = useState(true);

  const getMenuItemActive = (url: string, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const postToAnalytics = async (pageURL: string) => {
    const role = user && user[1].role ? user[1].role : "";
    const userId = user && user[1].userId ? user[1].userId : "";
    let dealerId;
    let dealerName;
    const delDetails = getStorage(dealerDetails);
    if (delDetails !== null) {
      dealerId = JSON.parse(delDetails).dealerId;
      dealerName = JSON.parse(delDetails).dealerName;
    } else {
      dealerId =
        user && user[1].assDealer.length > 0
          ? user[1].assDealer.filter((o: any) => o.isDefault === true)[0]
              .dealerId
          : "";
      dealerName =
        user && user[1].assDealer.length > 0
          ? user[1].assDealer.filter((o: any) => o.isDefault === true)[0].name
          : "";
    }
    await globalSaveAnalytics(
      userId,
      role,
      pageURL,
      "0",
      dealerId,
      dealerName,
      "",
      user[1].email
    );
  };

  const tabClick = (url: string) => {
    postToAnalytics(url);
    history.push(`${url}`);
  };

  useEffect(() => {
    getMessages();
    getContractStates();
    getLoanAppsCount();
    setDefaultPageLength();
    checkDbAvailable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDbAvailable = () => {
    let dealerId = "";
    if (user[1].role === dealerEmployee || user[1].role === dealerAdmin) {
      const setDealerId = getStorage(dealerIdURL);
      if (setDealerId) {
        dealerId = setDealerId;
      } else {
        const dlr = user[1].assDealer.filter(
          (del: any) => del.isDefault === true
        );
        if (dlr && dlr.length > 0) {
          dealerId = dlr[0].dealerId;
        } else {
          dealerId = user[1].assDealer[0].dealerId;
        }
      }
      validateDashboardData(dealerId);
    } else {
      const setDealerId = getStorage(dealerIdURL);
      if (setDealerId) {
        dealerId = setDealerId;
        validateDashboardData(dealerId);
      }
    }
  };

  const validateDashboardData = async (dealerId: any) => {
    const result = await getDashboardDetNoRet(dealerId);
    if (result?.data) {
      setIsDbdAvailable(true);
    } else {
      setIsDbdAvailable(false);
    }
  };

  const setDefaultPageLength = () => {
    const defVal = getStorage(dataTblPgLen);
    if (defVal === null) {
      setPageLength(defPgLen);
    }
  };

  const handleAddLoan = () => {
    if (history.location.pathname !== "/addLoan/loan") {
      getRetailerData();
    }
  };

  const changeLoanStatus = () => {
    updateExistingLoan();
  };

  const continueLoanAdd = () => {
    setShowIsExist(false);
    window.location.href = "/addLoan/loan";
  };

  const updateExistingLoan = async () => {
    const appId = getStorage(loanAppId) !== null ? getStorage(loanAppId) : 0;
    const status = enumLoanStatus.Pending;
    await changesStatus(Number(appId), status);
    clearStorage(loanAppId);
    clearStorage(progressBar);
    clearStorage(userActiveTabKey);
    setShowIsExist(false);
    setShow(true);
  };

  const getRetailerData = async () => {
    let delId = "";
    const delDetails = getStorage(dealerDetails);
    if (delDetails !== null) {
      delId = JSON.parse(delDetails).dealerId;
    }

    if (!delId) {
      if (user[1].role === dealerEmployee || user[1].role === dealerAdmin) {
        const dealer = user[1].assDealer.filter(
          (del: any) => del.isDefault === true
        )[0];
        if (dealer) {
          delId = dealer.dealerId;
        }
      }
    }
    postToAnalytics("/addLoan/loan");
    const res = await getRetailerLoanOnPageLoad(delId);
    if (res && res.data) {
      clearStorage(loanAppId);
      clearStorage(progressBar);
      clearStorage(userActiveTabKey);
      setStorage(loanAppId, res.data.id);
      setStorage(progressBar, 0);
      setShowIsExist(true);
    } else {
      setShow(true);
    }
    validateToken();
  };

  const validateToken = async () => {
    await checkAndUpdateToken();
  };

  const openSingleAddLoan = () => {
    clearStorage(selectedLoanType);
    setStorage(userActiveTabKey, 0);
    setStorage(progressBar, 0);
    clearStorage(loanAppId);
    setStorage(selectedLoanType, sinLnType);
    setShow(false);
    window.location.href = "/addLoan/loan";
  };

  const openJointAddLoan = () => {
    clearStorage(selectedLoanType);
    setStorage(userActiveTabKey, 0);
    setStorage(progressBar, 0);
    clearStorage(loanAppId);
    setStorage(selectedLoanType, jntLnType);
    setShow(false);
    window.location.href = "/addLoan/loan";
  };

  const logoutClick = () => {
    //Below methods moved to Logout page due to App Entry page leaving issue.
    //clearDefRoute();
    //clearAllStorages();
    history.push("/logout");
    postToAnalytics("/logout");
  };

  const clearImpersonation = () => {
    clearStorage(dealerDetails);
    clearStorage(dealerIdURL);
    defaultDataTableValues();
    if (location.pathname.includes("dashboard") === true) {
      postToAnalytics("/dashboard/dashboard");
      window.location.href = "/dashboard/dashboard";
    } else {
      postToAnalytics("/dealer/allapps");
      window.location.href = "/dealer/allapps";
    }
  };

  const defaultDataTableValues = async () => {
    await resetDataTableValues(
      enumDataTableTabs.AllApps,
      "",
      defaultPageNumber,
      defaultSortColumn,
      dtSortOrder
    );
  };

  const clearEmpHierImper = () => {
    clearStorage(dealerDetails);
    clearStorage(dealerIdURL);
    defaultDataTableValues();
    if (location.pathname.includes("dashboard") === true) {
      postToAnalytics("/dashboard/dashboard");
      window.location.href = "/dashboard/dashboard";
    } else {
      postToAnalytics("/dealer/allapps");
      window.location.href = "/dealer/allapps";
    }
  };

  const handleEmpHierSearch = (query: any) => {
    getEmpHierSerDlr(query);
  };

  const getEmpHierSerDlr = async (query: any) => {
    setIsLoading(true);
    const result = await getEmpHierDlrSearch(user[1].email, query);
    const output = result?.data;
    if (output) {
      setOptions(output);
      setIsLoading(false);
    }
  };

  const selectedEmpHierValue = (selected: any) => {
    debugger;
    const selectedTypeHead = selected.selected;
    if (selectedTypeHead.length > 0) {
      setEmpHieDealerDetails(selectedTypeHead[0]);
      clearStorage(dealerIdURL);
      defaultDataTableValues();
      setStorage(dealerIdURL, selectedTypeHead[0].clientDealerId);
      validateDashboardData(selectedTypeHead[0].clientDealerId);
      if (location.pathname.includes("dashboard") === true) {
        postToAnalytics("/dashboard/dashboard");
        window.location.href = "/dashboard/dashboard";
      } else {
        postToAnalytics("/dealer/allapps");
        window.location.href = "/dealer/allapps";
      }
    }
  };

  const setEmpHieDealerDetails = (dealerData: any) => {
    const data = {
      dealerId: dealerData.clientDealerId,
      dealerName: dealerData.dealerName,
      dealerAddress: null,
      dealerLocation: null,
    };
    clearStorage(dealerDetails);
    setStorage(dealerDetails, JSON.stringify(data));
  };

  const clearLocations = () => {
    clearStorage(dealerDetails);
    clearStorage(dealerIdURL);
    defaultDataTableValues();
    const menuTabs = user[1].menuTabPermission;
    let defRoute = DefaultMenuTabRoute(menuTabs);

    if (location.pathname.includes("dashboard") === true) {
      postToAnalytics("/dashboard/dashboard");
      window.location.href = "/dashboard/dashboard";
    } else {
      postToAnalytics(defRoute);
      window.location.href = defRoute;
    }
  };

  const selectedValue = (selected: any) => {
    const selectedTypeHead = selected.selected;
    if (selectedTypeHead.length > 0) {
      defaultDataTableValues();
      setDealerDetails(selectedTypeHead[0]);
      clearStorage(dealerIdURL);
      setStorage(dealerIdURL, selectedTypeHead[0].clientDealerId);
      validateDashboardData(selectedTypeHead[0].clientDealerId);
      if (location.pathname.includes("dashboard") === true) {
        postToAnalytics("/dashboard/dashboard");
        window.location.href = "/dashboard/dashboard";
      } else {
        postToAnalytics("/dealer/allapps");
        window.location.href = "/dealer/allapps";
      }
    }
  };

  const setDealerDetails = (dealerData: any) => {
    const data = {
      dealerId: dealerData.clientDealerId,
      dealerName: dealerData.dealerName,
      dealerAddress: dealerData.address,
      dealerLocation: `${dealerData.city} ${dealerData.stateCd} ${dealerData.postalCd}`,
    };
    clearStorage(dealerDetails);
    setStorage(dealerDetails, JSON.stringify(data));
  };

  const getDealerInfo = async (dealerId: string) => {
    const data = await getDealersById(dealerId);
    if (data?.data.length > 0) {
      const menuTabs = user[1].menuTabPermission;
      let defRoute = DefaultMenuTabRoute(menuTabs);

      if (data?.data.length === 1) {
        setDealerDetails(data?.data[0]);
        postToAnalytics(defRoute);
        window.location.href = defRoute;
      } else {
        const selData = data?.data.filter(
          (res: any) => res.clientDealerId === res.primaryDealerId
        );
        setDealerDetails(selData[0]);
        postToAnalytics(defRoute);
        window.location.href = defRoute;
      }
    }
  };

  const handleRoleChange = (event: any) => {
    if (event.target.selectedIndex > 0) {
      defaultDataTableValues();
      validateDashboardData(event.target.value);
      setselectedDealer(event.target[event.target.selectedIndex].text);
      clearStorage(dealerIdURL);
      setStorage(dealerIdURL, event.target.value);
      getDealerInfo(event.target.value);
    }
  };

  const handleSearch = (query: any) => {
    getSearchDealer(query);
  };

  const getSearchDealer = async (query: any) => {
    setIsLoading(true);
    const result = await getSearchDealers(query);
    const output = result?.data.data;
    if (output) {
      setOptions(output);
      setIsLoading(false);
    }
  };

  const filterBy = () => true;

  const getLoanAppsCount = async () => {
    const res: IAllAppsCount = await getAllLoanAppsCount(user[1].assDealer);
    setAppsCount(res);
  };

  const menuTabs = user[1].menuTabPermission;
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav`}>
        {menuTabs && menuTabs.length > 0 ? (
          <>
            {menuTabs.filter(
              (tabId: number) => tabId === enumMenuTabs.Dashboard
            ).length > 0 ? (
              <>
                {isDbdAvailable === true ? (
                  <li
                    className={`bottom-border menu-item ${getMenuItemActive(
                      "/dashboard/dashboard",
                      false
                    )}`}
                  >
                    <div
                      className="menu-link"
                      onClick={() => tabClick("/dashboard/dashboard")}
                    >
                      <span className="fa fa-chart-line menu-icon fa-menu-icon"></span>
                      <span className="menu-text font-color">Dashboard</span>
                    </div>
                  </li>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {menuTabs.filter((tabId: number) => tabId === enumMenuTabs.AllApps)
              .length > 0 ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dealer/allapps",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dealer/allapps")}
                >
                  <span className="fa fa-file-alt fa-menu-icon"></span>
                  <span className="menu-text font-color">All Applications</span>
                  <div>
                    <span className="menu-text number-badge">
                      {appsCount.AllApps}
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
            {menuTabs.filter(
              (tabId: number) => tabId === enumMenuTabs.Underwriting
            ).length > 0 ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dealer/appsUnderwriting",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dealer/appsUnderwriting")}
                >
                  <span className="fa fa-file-signature fa-menu-icon"></span>
                  <span className="menu-text font-color">
                    Apps Underwriting
                  </span>
                  <div>
                    <span className="menu-text number-badge">
                      {appsCount.UnderWriting}
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
            {menuTabs.filter((tabId: number) => tabId === enumMenuTabs.Funding)
              .length > 0 ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dealer/appsFunding",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dealer/appsFunding")}
                >
                  <span className="fa fa-file-contract fa-menu-icon"></span>
                  <span className="menu-text font-color">Apps Funding</span>
                  <div>
                    <span className="menu-text number-badge">
                      {appsCount.Funding}
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
            {menuTabs.filter((tabId: number) => tabId === enumMenuTabs.Booked)
              .length > 0 ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dealer/appsBooked",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dealer/appsBooked")}
                >
                  <span className="fa fa-file-invoice-dollar fa-menu-icon"></span>
                  <span className="menu-text font-color">
                    Booked, Funds on Hold
                  </span>
                  <div>
                    <span className="menu-text number-badge">
                      {appsCount.Booked}
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
            {menuTabs.filter((tabId: number) => tabId === enumMenuTabs.Funded)
              .length > 0 ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dealer/appsFunded",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dealer/appsFunded")}
                >
                  <span className="fa fa-dollar-sign fa-menu-icon"></span>
                  <span className="menu-text font-color">Funded</span>
                  <div>
                    <span className="menu-text number-badge">
                      {appsCount.Funded}
                    </span>
                  </div>
                </div>
              </li>
            ) : (
              ""
            )}
          </>
        ) : (
          <>
            {isDbdAvailable === true ? (
              <li
                className={`bottom-border menu-item ${getMenuItemActive(
                  "/dashboard/dashboard",
                  false
                )}`}
              >
                <div
                  className="menu-link"
                  onClick={() => tabClick("/dashboard/dashboard")}
                >
                  <span className="fa fa-chart-line menu-icon fa-menu-icon"></span>
                  <span className="menu-text font-color">Dashboard</span>
                </div>
              </li>
            ) : (
              ""
            )}
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/dealer/allapps",
                false
              )}`}
            >
              <div
                className="menu-link"
                onClick={() => tabClick("/dealer/allapps")}
              >
                <span className="fa fa-file-alt fa-menu-icon"></span>
                <span className="menu-text font-color">All Applications</span>
                <div>
                  <span className="menu-text number-badge">
                    {appsCount.AllApps}
                  </span>
                </div>
              </div>
            </li>
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/dealer/appsUnderwriting",
                false
              )}`}
            >
              <div
                className="menu-link"
                onClick={() => tabClick("/dealer/appsUnderwriting")}
              >
                <span className="fa fa-file-signature fa-menu-icon"></span>
                <span className="menu-text font-color">Apps Underwriting</span>
                <div>
                  <span className="menu-text number-badge">
                    {appsCount.UnderWriting}
                  </span>
                </div>
              </div>
            </li>
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/dealer/appsFunding",
                false
              )}`}
            >
              <div
                className="menu-link"
                onClick={() => tabClick("/dealer/appsFunding")}
              >
                <span className="fa fa-file-contract fa-menu-icon"></span>
                <span className="menu-text font-color">Apps Funding</span>
                <div>
                  <span className="menu-text number-badge">
                    {appsCount.Funding}
                  </span>
                </div>
              </div>
            </li>
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/dealer/appsBooked",
                false
              )}`}
            >
              <div
                className="menu-link"
                onClick={() => tabClick("/dealer/appsBooked")}
              >
                <span className="fa fa-file-invoice-dollar fa-menu-icon"></span>
                <span className="menu-text font-color">
                  Booked, Funds on Hold
                </span>
                <div>
                  <span className="menu-text number-badge">
                    {appsCount.Booked}
                  </span>
                </div>
              </div>
            </li>
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/dealer/appsFunded",
                false
              )}`}
            >
              <div
                className="menu-link"
                onClick={() => tabClick("/dealer/appsFunded")}
              >
                <span className="fa fa-dollar-sign fa-menu-icon"></span>
                <span className="menu-text font-color">Funded</span>
                <div>
                  <span className="menu-text number-badge">
                    {appsCount.Funded}
                  </span>
                </div>
              </div>
            </li>
          </>
        )}

        {user[1].isAppEntryUser === true ? (
          // ||
          // user[1].role === superAdmin ||
          // user[1].role === readonly
          getStorage(dealerDetails) ||
          (user[1].assDealer && user[1].assDealer.length > 0) ? (
            <li
              className={`bottom-border menu-item ${getMenuItemActive(
                "/addLoan/loan",
                false
              )}`}
            >
              <div className="menu-link" onClick={handleAddLoan}>
                <span className="fa fa-file-medical fa-menu-icon"></span>
                <span className="menu-text font-color">Create Application</span>
              </div>
            </li>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <li
          className={`bottom-border menu-item ${getMenuItemActive(
            "/logout",
            false
          )}`}
        >
          <div onClick={logoutClick} className="menu-link">
            <span className="fa flaticon-logout fa-menu-icon"></span>
            <span className="menu-text font-color">Logout</span>
          </div>
        </li>

        {/* Checking login user is from Emp Hierarchy table or not */}
        {user[1].isEmpHierarchy === false ? (
          user[1].role === verosEmployee ||
          user[1].role === verosAdmin ||
          user[1].role === superAdmin ||
          user[1].role === readonly ? (
            <li className="menu-section row pl-4">
              <div className={`dlrImpersonator`}>
                <div
                  className="col-lg-12 pr-0 pl-0"
                  style={{ marginBottom: ".3rem" }}
                >
                  <Form.Label style={{ fontSize: "12px", color: "white" }}>
                    Dealer Impersonator
                  </Form.Label>
                  <button
                    onClick={clearImpersonation}
                    className={`btn btn-primary float-right`}
                    style={{ padding: "0px", width: "45px", height: "20px" }}
                  >
                    All
                  </button>
                </div>
                <div className="col-lg-12 pr-0 pl-0" style={{ width: "225px" }}>
                  <AsyncTypeahead
                    defaultSelected={options.slice(0, 1)}
                    filterBy={filterBy}
                    id="dealerSearch"
                    isLoading={isLoading}
                    labelKey="dealerName"
                    minLength={3}
                    onSearch={handleSearch}
                    options={options}
                    placeholder="Dealer Name"
                    onChange={(selected) => selectedValue({ selected })}
                    renderMenuItemChildren={(option: any, props) => (
                      <Fragment>
                        <span>{option.dealerName}</span>
                      </Fragment>
                    )}
                  />
                </div>
              </div>
            </li>
          ) : (
            <li className="menu-section row pl-4">
              {user[1].assDealer && user[1].assDealer.length > 1 && (
                <div className={`dlrImpersonator`}>
                  <div
                    className="col-lg-12 pr-0 pl-0"
                    style={{ marginBottom: ".3rem" }}
                  >
                    <Form.Label style={{ fontSize: "12px", color: "white" }}>
                      Dealer Locations
                    </Form.Label>
                    <button
                      onClick={clearLocations}
                      className={`btn btn-primary float-right`}
                      style={{
                        padding: "0px",
                        width: "45px",
                        height: "20px",
                      }}
                    >
                      All
                    </button>
                  </div>
                  <div className="col-lg-12 pr-0 pl-0">
                    <Form.Control
                      size="sm"
                      as="select"
                      name="role"
                      style={{
                        height: "calc(1.5em + 1.3rem + 2px)",
                        width: "225px",
                      }}
                      value={
                        getStorage(dealerIdURL) !== null
                          ? getStorage(dealerIdURL)?.toString()
                          : ""
                      }
                      onChange={(event) => {
                        handleRoleChange(event);
                      }}
                    >
                      <option value="">Select</option>
                      {user[1].assDealer &&
                        user[1].assDealer.map((user: any, i: number) => {
                          return (
                            <option key={i} value={user.dealerId}>
                              {user.name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </div>
                </div>
              )}
            </li>
          )
        ) : (
          // Emp Hierarchy Impersonation
          <li className="menu-section row pl-4">
            <div className={`dlrImpersonator`}>
              <div
                className="col-lg-12 pr-0 pl-0"
                style={{ marginBottom: ".3rem" }}
              >
                <Form.Label style={{ fontSize: "12px", color: "white" }}>
                  Dealer Impersonator
                </Form.Label>
                <button
                  onClick={clearEmpHierImper}
                  className={`btn btn-primary float-right`}
                  style={{ padding: "0px", width: "45px", height: "20px" }}
                >
                  All
                </button>
              </div>
              <div className="col-lg-12 pr-0 pl-0" style={{ width: "225px" }}>
                <AsyncTypeahead
                  defaultSelected={options.slice(0, 1)}
                  filterBy={filterBy}
                  id="dealerSearch"
                  isLoading={isLoading}
                  labelKey="dealerName"
                  minLength={3}
                  onSearch={handleEmpHierSearch}
                  options={options}
                  placeholder="Dealer Name"
                  onChange={(selected) => selectedEmpHierValue({ selected })}
                  renderMenuItemChildren={(option: any, props) => (
                    <Fragment>
                      <span>{option.dealerName}</span>
                    </Fragment>
                  )}
                />
              </div>
            </div>
          </li>
        )}
      </ul>

      {/* {Popup for selecting single or joint application new loan application creation} */}
      <Modal
        show={show}
        id="noApptsPopupId"
        className="popupDefaultRightPadding"
        backdrop="static"
        centered
      >
        <div className="mcBorder">
          <Modal.Header>
            <Modal.Title className="row">
              <h5 style={{ fontWeight: 400 }}>
                How many applicants are applying?
              </h5>
            </Modal.Title>
            <button
              type="button"
              className="popupCloseNew"
              onClick={handleIsExistClose}
            >
              <i className="la la-remove" style={{ fontSize: "large" }}></i>
            </button>
          </Modal.Header>
          <Modal.Body style={{ padding: "25px" }}>
            <div className="row">
              <div
                className="col-lg-4 offset-lg-1 text-center b br-10 p-0"
                id="divSingle"
              >
                <button
                  id="btnSingle"
                  type="button"
                  className="btn btn-default"
                  onClick={openSingleAddLoan}
                >
                  <i className="fa fa-user icnSingle mb-2"></i>
                  <div>
                    <span className="spnAppType">Single</span>
                  </div>
                </button>
              </div>
              <div
                className="col-lg-4 offset-lg-2 text-center b br-10 p-0"
                id="divJoint"
              >
                <button
                  id="btnJoint"
                  type="button"
                  className="btn btn-default"
                  onClick={openJointAddLoan}
                >
                  <i className="fa fa-user-friends icnJoint mb-2"></i>
                  <div>
                    <span className="spnAppType">Joint</span>
                  </div>
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>

      {/* {Alert popup for working with existing loan application or to create new loan application} */}
      <Modal
        show={showIsExist}
        id="alreadyDataPopupId"
        className="popupDefaultRightPadding"
        backdrop="static"
        centered
      >
        <div>
          <Modal.Header>
            <div style={{ fontSize: "14px" }}>
              Press <b>Continue</b> to work on last unsubmitted application
            </div>
            <div>
              <button
                type="button"
                onClick={handleCloseIsExist}
                className="popupCloseNew"
              >
                <i className="la la-remove" style={{ fontSize: "large" }}></i>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body style={{ padding: "25px" }}>
            <div className="row">
              <div className="col-lg-6 text-center br-10 p-0"></div>
              <div className="col-lg-6 text-center br-10 p-0">
                <button
                  type="button"
                  className="btn btn-primary mr-2"
                  onClick={continueLoanAdd}
                >
                  <div>
                    <span>Continue</span>
                  </div>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={changeLoanStatus}
                >
                  <div>
                    <span>New Entry</span>
                  </div>
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <RefreshTimer />
      <IdleTimerContainer />
      <ClearDefaultRoute />
    </>
  );
});
export default AsideMenuList;
