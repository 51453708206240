import { apiGateway } from "../../common/apiURLs";
import { downloadFile, get } from "../../common/commonApi";
import { dashboardCookie, dealerCookie } from "../../common/csrfService";
import { dashboardAppsQueryCount } from "../../common/helperContainer";

export const getDashboardDetNoRet = async (dealerId: any) => {
  const url = `/dashboardapi/api/GetDashBoardVolume/${dealerId}`;
  return await get(apiGateway, url, false, dashboardCookie);
};

export const getDashboardDetails = async (dealerId: any) => {
  const url = `/dashboardapi/api/GetDashBoardVolume/${dealerId}`;
  return await get(apiGateway, url, true, dashboardCookie);
};

export const getOTRData = (dealerId: any) => {
  const url = `${apiGateway}/dashboardapi/api/Otr/${dealerId}?pageNumber=1&pageSize=${dashboardAppsQueryCount}`;
  return url;
};

export const getbuyBacksData = (dealerId: any) => {
  const url = `${apiGateway}/dashboardapi/api/buyback/${dealerId}?pageNumber=1&pageSize=${dashboardAppsQueryCount}`;
  return url;
};

export const getAncillaryData = (dealerId: any) => {
  const url = `${apiGateway}/dashboardapi/api/gap/${dealerId}?pageNumber=1&pageSize=${dashboardAppsQueryCount}`;
  return url;
};

export const getwithHoldData = (dealerId: any) => {
  const url = `${apiGateway}/dashboardapi/api/WithholdDetails/${dealerId}?pageNumber=1&pageSize=${dashboardAppsQueryCount}`;
  return url;
};

export const calculateRebateRefund = async (dealerId: any) => {
  const queryUrl = `/dealerapi/api/calculaterebaterefund/${dealerId}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const dashboardExport = async (
  dealerId: any,
  type: string,
  searchText: string,
  fileName: string
) => {
  const queryUrl = `/dashboardapi/api/dashboardexport?page=1&limit=${dashboardAppsQueryCount}&dealerId=${dealerId}&type=${type}&searchText=${searchText}`;
  return await downloadFile(
    apiGateway,
    queryUrl,
    "",
    fileName,
    dashboardCookie
  );
};

export const getDelinquency = async () => {
  const queryUrl = `/dashboardapi/api/getdelinquency`;
  return await get(apiGateway, queryUrl, true, dashboardCookie);
};
