import axios from "axios";
import { toastMessage } from "../../../common/toastMessage";
import { enumMessageType } from "../../../common/helperContainer";
import { getAuthToken } from "../../../common/helperMethods";
import {
  SessionExpiredText,
  ErrorOccurredText,
} from "../../../common/messageContainer";

export const getWithAuth = async (baseUrl: string, queryUrl: string) => {
  const headers = {
    Accept: "application/json",
    Authorization: `bearer ${getAuthToken()}`,
  };

  try {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(`${baseUrl}${queryUrl}`, {
      headers: headers,
    });
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    }
    if (response.status === 401) {
      toastMessage(enumMessageType.Error, SessionExpiredText);
      window.location.href = "/logout";
    }
    if (response.status === 500) {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } catch (err) {
    catchBlockError(err);
  }
};

const catchBlockError = (err: any) => {
  if (err.response) {
    if (err.response.status === 404) {
      if (err.response.data[0]) {
        toastMessage(enumMessageType.Error, err.response.data[0].description);
      } else {
        toastMessage(enumMessageType.Error, ErrorOccurredText);
      }
    } else if (err.response.status === 401) {
      toastMessage(enumMessageType.Error, SessionExpiredText);
      window.location.href = "/logout";
    } else {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } else {
    toastMessage(enumMessageType.Error, ErrorOccurredText);
  }
};

export const getWithoutAuth = async (baseUrl: string, queryUrl: string) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `bearer ${getAuthToken()}`,
  };

  try {
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(`${baseUrl}${queryUrl}`, {
      headers: headers,
    });
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    }
    if (response.status === 401) {
      toastMessage(enumMessageType.Error, SessionExpiredText);
      window.location.href = "/logout";
    }
    if (response.status === 500) {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } catch (err) {
    catchBlockError(err);
  }
};

export const post = async (baseUrl: string, queryUrl: string, model: any) => {
  if (model) {
    try {
      axios.defaults.withCredentials = true;
      axios.defaults.headers.post["Content-Type"] =
        "application/x-www-form-urlencoded";
      const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response;
    } catch (error) {
      errorBlock(error);
      return "";
    }
  } else {
    try {
      const response = await axios.post(`${baseUrl}${queryUrl}`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response;
    } catch (error) {
      errorBlock(error);
      return "";
    }
  }
};

export const regularPost = async (
  baseUrl: string,
  queryUrl: string,
  model: any,
  contentType: string
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = contentType;
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response;
  } catch (error) {
    errorBlock(error);
    return "";
  }
};

export const winToken = async (
  baseUrl: string,
  queryUrl: string,
  model: any
) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    const response = await axios.post(`${baseUrl}${queryUrl}`, model, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    return response;
  } catch (error) {
    return "";
  }
};

export const getAdDetails = async (baseUrl: string, queryUrl: string) => {
  try {
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    const response: any = await axios.get(`${baseUrl}${queryUrl}`, {
      headers: { Accept: "application/json" },
    });
    if (response.status === 200 || response.status === 201) {
      const json = response.data;
      return { data: json };
    } else {
      return "";
    }
  } catch (err) {
    return "";
  }
};

const errorBlock = (err: any) => {
  if (err.response.status === 404) {
    if (err.response.data.errors) {
      const errorDec = err.response.data.errors[0].description;
      if (errorDec) {
        toastMessage(enumMessageType.Error, errorDec);
      } else {
        toastMessage(enumMessageType.Error, err.response.data.errors[0]);
      }
    } else if (err.response.data) {
      toastMessage(enumMessageType.Error, err.response.data[0].description);
    } else {
      toastMessage(enumMessageType.Error, ErrorOccurredText);
    }
  } else {
    toastMessage(enumMessageType.Error, ErrorOccurredText);
  }
};
