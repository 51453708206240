import { apiGateway } from "../../common/apiURLs";
import {
  get,
  post,
  uploadFile,
  downloadFile,
  getReturnError,
  uploadFileNoReturn,
  get3rdParty,
  carletonPut,
  carletonPost,
  postWithCustMesg,
} from "../../common/commonApi";
import {
  dealerCookie,
  fedExCookie,
  loanCookie,
  taxonomyCookie,
} from "../../common/csrfService";
import {
  contractTypes,
  eSignStatusCompInPrgEnum,
  enumContentTypes,
  formsListQueryCount,
} from "../../common/helperContainer";
import { DataSubmittedSuccessfullyText } from "../../common/messageContainer";

export const getAllLoanApps = apiGateway + `/loanapp/api/allloanapps`;

export const dummy = async () => {
  const queryUrl = `/loanapp/api/allloanapps`;
  return await post(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getloanAppDetails = async (appNo: any) => {
  const queryUrl = `/loanapp/api/loanapps/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const getNetPrdsDetails = async (id: any) => {
  const queryUrl = `/loanapp/api/netproceeds/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getFrmslnkDetails = async (dealerName: any) => {
  var queryUrl = `/dealerapi/api/DealerForms?Page=${1}&Limit=${formsListQueryCount}&DealerNames=${dealerName}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const exportToExcel = async (params: any, fileName: string) => {
  var queryUrl = `/loanapp/api/allloanreport?${params}`;
  return await downloadFile(apiGateway, queryUrl, "", fileName, loanCookie);
};

export const getFormsDownload = async (fileName: any) => {
  const queryUrl = `/dealerapi/api/DealerForms/Download/${fileName}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const getStipsDetails = async (id: any) => {
  const queryUrl = `/loanapp/api/Stipulation/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getDealerByClientDlrId = async (dealerId: any) => {
  const queryUrl = `/dealerapi/api/dealer/details/${dealerId}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const getDealerByPrimaryDlrId = async (dealerId: any) => {
  const queryUrl = `/dealerapi/api/dealer/primarydetails/${dealerId}`;
  return await get(apiGateway, queryUrl, true, dealerCookie);
};

export const uploadFiles = async (model: any) => {
  const queryUrl = "/loanapp/api/SaveFiles";
  return await uploadFile(apiGateway, queryUrl, model, loanCookie);
};

export const createShipment = async (model: any) => {
  const queryUrl = `/fedEx/api/fedEx/createShipment`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    true,
    fedExCookie
  );
};

export const getFedExPDF = async (appNo: any) => {
  const queryUrl = `/fedEx/api/fedEx/${appNo}`;
  return await get(apiGateway, queryUrl, true, fedExCookie);
};

export const getAllFedExLabels = async (appNo: any) => {
  const queryUrl = `/fedEx/api/FedEx/AllFedExLabels/${appNo}`;
  return await get(apiGateway, queryUrl, true, fedExCookie);
};

export const getFundingUpdateData = async (id: any) => {
  const queryUrl = `/loanapp/api/funding/${id}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getRehashData = async (appNo: any) => {
  const queryUrl = `/loanapp/api/Rehash/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const getRehashDataNoReturn = async (appNo: any) => {
  const queryUrl = `/loanapp/api/Rehash/${appNo}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const saveRehash = async (model: any) => {
  const queryUrl = `/loanapp/api/Rehash`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const revertRehash = async (model: any) => {
  const queryUrl = `/loanapp/api/revertrehash`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const saveDealerComments = async (model: any) => {
  const queryUrl = `/dealerapi/api/dealer/dealercomments`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    true,
    dealerCookie
  );
};

export const getFundedPopupDetails = async (appNo: number) => {
  const queryUrl = `/loanapp/api/getFundedPopup/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getDealerCommentsByAppNo = async (appNo: number) => {
  const queryUrl = `/dealerapi/api/dealer/dealercomments/${appNo}`;
  return await get(apiGateway, queryUrl, false, dealerCookie);
};

export const updateDealerComments = async (appNo: number) => {
  const queryUrl = `/dealerapi/api/dealer/updateDealerComments/${appNo}`;
  return await post(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    false,
    dealerCookie
  );
};

export const saveVehicleInfo = async (model: any) => {
  const queryUrl = `/loanapp/api/vehicleinfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveVehicleInfoReturnErr = async (model: any) => {
  const queryUrl = `/loanapp/api/vehicleinfo`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveCustomerInfo = async (model: any) => {
  const queryUrl = `/loanapp/api/customerinfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getCustInfo = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/getcustomerinfo/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getVehInfo = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/getvehicleinfo/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getConfirmContract = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getFusePreviewContract = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/showfusecontractpdf/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const createContract = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/create/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const oldCreateContract = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/oldcreate/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const createDocuSign = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/docusign/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const fuseSendForEsign = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/fusesendforesign`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const saveErrorLog = async (model: any) => {
  const queryUrl = `/taxonomy/api/taxonomy/SaveErrorInfo`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    taxonomyCookie
  );
};

export const getAppSetting = async (microService: string, key: string) => {
  const queryUrl = `/taxonomy/api/Taxonomy/GetAppSettings/${microService}/${key}`;
  return await get(apiGateway, queryUrl, true, taxonomyCookie);
};

export const savePackageUploadFile = async (model: any) => {
  const queryUrl = "/loanapp/api/savepackageuploadfile";
  return await uploadFileNoReturn(apiGateway, queryUrl, model, loanCookie);
};

export const getPackageUploadFiles = async (
  appNo: number,
  folderName: string
) => {
  const queryUrl = `/loanapp/api/getpackageuploadfiles/${appNo}/${folderName}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const submitPackageUpload = async (model: any) => {
  const queryUrl = `/loanapp/api/packageupload`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getAulRates = async (appNo: any) => {
  const queryUrl = `/loanapp/api/aulrates/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const updateUnreadComments = async (
  appNo: Number,
  messageFrom: string
) => {
  const queryUrl = `/loanapp/api/updateunreadcomments/${appNo}/true/${messageFrom}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getContractTags = async (stateCode: string, loanapp: number) => {
  const queryUrl = `/loanapp/api/contract/contracttags/${stateCode}/${loanapp}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const getContractPopupTags = async (
  stateCode: string,
  loanapp: number,
  popupType: number
) => {
  const queryUrl = `/loanapp/api/contract/contractpopuptags/${stateCode}/${loanapp}/${popupType}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateTags = async (appNo: number, model: any) => {
  const queryUrl = `/loanapp/api/contract/updatecontracttags/${appNo}`;
  return await carletonPut(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getVehInfoFromGov = async (vinNo: any) => {
  const queryUrl = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vinNo}?format=json`;
  return await get3rdParty(queryUrl);
};

export const updateBuyerEmails = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/updatebuyeremails`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateContractEmails = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/updatecontractemails`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getContractAppDetails = async (appNo: any) => {
  const queryUrl = `/loanapp/api/getContractAppDetails/${appNo}`;
  return await getReturnError(apiGateway, queryUrl, loanCookie);
};

export const directContractProcess = async (model: any) => {
  const queryUrl = `/loanapp/api/directcontractprocess`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const fuseContractProcess = async (model: any) => {
  const queryUrl = `/loanapp/api/fusecontractprocess`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const updateContractType = async (model: any) => {
  const queryUrl = `/loanapp/api/updatecontracttype`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const getApptEmails = async (appNo: Number) => {
  const queryUrl = `/loanapp/api/contract/getapplicantemails/${appNo}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const cancelContract = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/cancelcontract`;
  if (model.contractType === contractTypes.Direct) {
    return await postWithCustMesg(
      apiGateway,
      queryUrl,
      model,
      enumContentTypes.json,
      true,
      loanCookie,
      model.eSignStatus === eSignStatusCompInPrgEnum.Complete
        ? DataSubmittedSuccessfullyText
        : "Your cancellation request has been received and is currently being processed."
    );
  } else {
    return await post(
      apiGateway,
      queryUrl,
      model,
      enumContentTypes.json,
      true,
      loanCookie
    );
  }
};

export const fusecancelContract = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/fusecancelcontract`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    loanCookie
  );
};

export const showContractPdf = async (appNo: number, contractType: string) => {
  const queryUrl = `/loanapp/api/contract/showcontractpdf/${appNo}/${contractType}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getCPIPremiumAmount = async (
  stateCode: string,
  amountFinanced: number,
  cpiTerm: number
) => {
  const queryUrl = `/loanapp/api/cpipremiumamount/${stateCode}/${amountFinanced}/${cpiTerm}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateLoanSource = async (model: any) => {
  const queryUrl = `/loanapp/api/updateloansource`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const getDealerSignDetails = async (loanapp: number) => {
  const queryUrl = `/loanapp/api/contract/dealersigndetails/${loanapp}`;
  return await get(apiGateway, queryUrl, false, loanCookie);
};

export const updateDealerSignDetails = async (model: any) => {
  const queryUrl = `/loanapp/api/contract/updatedealersigndetails`;
  return await carletonPost(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    loanCookie
  );
};

export const refreshFuseContractStatus = async (
  appNo: Number,
  retryCount: Number
) => {
  const queryUrl = `/loanapp/api/contract/refreshfusecontractstatus/${appNo}/${retryCount}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};
