import { apiGateway } from "../../common/apiURLs";
import {
  get,
  post,
  put,
  regenerateToken,
  postWithCustMesg,
} from "../../common/commonApi";
import { IAllAppsCount } from "./adminModel";
import {
  dealerListQueryCount,
  dealerIdURL,
  underStatus,
  bookedStatus,
  fundedStatus,
  fundingStatus,
  enumContentTypes,
} from "../../common/helperContainer";
import { getStorage } from "../../common/storageHelper";
import { ResetPassSuccessText } from "../../common/messageContainer";
import {
  analyticsCookie,
  dealerCookie,
  loanCookie,
  taxonomyCookie,
  umiCookie,
} from "../../common/csrfService";

export const getUserListURL = apiGateway + `/umi/api/user`;

export const saveVerosUser = async (model: any) => {
  const queryUrl = "/umi/user/register";
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    umiCookie
  );
};

export const saveDealerUser = async (model: any) => {
  const queryUrl = "/umi/user/register/dealer";
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    umiCookie
  );
};

export const updateVerosUser = async (model: any) => {
  const queryUrl = `/umi/user/${model.userId}`;
  return await put(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    umiCookie
  );
};

export const updateDealerUser = async (model: any) => {
  const queryUrl = `/umi/user/dealer/${model.userId}`;
  return await put(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    umiCookie
  );
};

export const deleteUser = async (model: any) => {
  const queryUrl = `/umi/user/delete`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    false,
    umiCookie
  );
};

export const getADUsertails = async (userName: any) => {
  const queryUrl = `/umi/GetActiveDirectoryUser?userName=${userName}`;
  return await get(apiGateway, queryUrl, true, umiCookie);
};

export const getUserById = async (userId: any) => {
  const queryUrl = `/umi/api/user/${userId}`;
  return await get(apiGateway, queryUrl, true, umiCookie);
};

export const getSearchDealers = async (search: string) => {
  var bodyFormData = new FormData();
  bodyFormData.append("searchValue", search);
  bodyFormData.append("page", "1");
  bodyFormData.append("limit", dealerListQueryCount.toString());
  const queryUrl = `/dealerapi/api/dealer/dealersearch`;
  return await post(
    apiGateway,
    queryUrl,
    bodyFormData,
    enumContentTypes.formencoded,
    false,
    dealerCookie
  );
};

export const getDecLogURLWithNoReturn = async (clientDealerId: any) => {
  const queryUrl = `/dealerapi/api/DealerExport/GetDecisionLogicUrl/${clientDealerId}`;
  return await get(apiGateway, queryUrl, false, dealerCookie);
};

export const getRoles = async (userType: any) => {
  const queryUrl = `/umi/roles?roleType=${userType}`;
  return await get(apiGateway, queryUrl, false, umiCookie);
};

export const userresetpassword = async (email: any) => {
  const queryUrl = `/umi/user/userresetpassword?userName=${email}`;
  const customMsg = ResetPassSuccessText;
  return await postWithCustMesg(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    true,
    umiCookie,
    customMsg
  );
};

export const forgetPassword = async (userName: string) => {
  const queryUrl = `/umi/ForgotPassword?userName=${userName}`;
  const customMsg = ResetPassSuccessText;
  return await postWithCustMesg(
    apiGateway,
    queryUrl,
    null,
    enumContentTypes.json,
    true,
    umiCookie,
    customMsg
  );
};

export const resetpassword = async (model: any) => {
  const queryUrl = `/umi/user/username/pasword`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.json,
    true,
    umiCookie
  );
};

export const getAllLoanAppsCount = async (assoDealers: any) => {
  let queryUrl = `/loanapp/api/loanapps/count`;

  let dlrParam = "";
  const setDealerId = getStorage(dealerIdURL);
  // Checking whethere dealer is impersonated or not
  if (setDealerId) {
    dlrParam = `&DealerIds=${setDealerId}`;
  } else {
    // Getting dealers from the login
    assoDealers.forEach((dlr: any) => {
      if (dlr) {
        dlrParam += `&DealerIds=${dlr.dealerId}`;
      }
    });
  }

  queryUrl =
    dlrParam === "" ? queryUrl : `${queryUrl}?${dlrParam.substring(1)}`;

  const res = await get(apiGateway, queryUrl, true, loanCookie);

  const resUnd = res?.data.filter((und: any) =>
    underStatus.includes(und.status)
  );
  const resBooked = res?.data.filter((und: any) =>
    bookedStatus.includes(und.status)
  );
  const resFunded = res?.data.filter((und: any) =>
    fundedStatus.includes(und.status)
  );
  const resFunding = res?.data.filter((und: any) =>
    fundingStatus.includes(und.status)
  );

  let sumTotal = 0;
  let sumResUnd = 0;
  let sumResBooked = 0;
  let sumResFunded = 0;
  let sumResFunding = 0;

  if (res?.data) {
    res?.data.forEach((num: any) => {
      sumTotal = num.count + sumTotal;
    });
  }
  if (resUnd) {
    resUnd.forEach((num: any) => {
      sumResUnd = num.count + sumResUnd;
    });
  }
  if (resBooked) {
    resBooked.forEach((num: any) => {
      sumResBooked = num.count + sumResBooked;
    });
  }
  if (resFunded) {
    resFunded.forEach((num: any) => {
      sumResFunded = num.count + sumResFunded;
    });
  }
  if (resFunding) {
    resFunding.forEach((num: any) => {
      sumResFunding = num.count + sumResFunding;
    });
  }

  const allCounts: IAllAppsCount = {
    AllApps: sumTotal,
    UnderWriting: sumResUnd,
    Funded: sumResFunded,
    Funding: sumResFunding,
    Booked: sumResBooked,
  };

  return allCounts;
};

export const saveAnalytics = async (model: any) => {
  const queryUrl = "/analytics/api/analytic/SaveAnalytics";
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    false,
    analyticsCookie
  );
};

export const saveUserSetting = async (model: any) => {
  const queryUrl = `/umi/user/usersettings`;
  return await post(
    apiGateway,
    queryUrl,
    model,
    enumContentTypes.formencoded,
    false,
    umiCookie
  );
};

export const getUpdatedToken = async (model: any) => {
  const queryUrl = "/umi/RefreshToken";
  return await regenerateToken(apiGateway, queryUrl, model, "", umiCookie);
};

export const getEmpHierDlrSearch = async (email: string, query: string) => {
  const queryUrl = `/loanapp/api/getemphierdlr/${email}/${query}`;
  return await get(apiGateway, queryUrl, true, loanCookie);
};

export const getParentIdData = async (parentId: number) => {
  const queryUrl = `/taxonomy/api/Taxonomy/parentid/${parentId}`;
  return await get(apiGateway, queryUrl, true, taxonomyCookie);
};
