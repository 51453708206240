/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/VerosCreditLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {
  setStorage,
  clearStorage,
  getStorage,
  clearAllStorages,
} from "../../../../../app/common/storageHelper";
import {
  userActiveTabKey,
  enumUserTabs,
  isProfile,
  selectedUserId,
  isEdit,
  imagePlaceHolder,
  dealerDetails,
  globalSearchValue,
  analyticUsrMgt,
  dealerEmployee,
  dealerAdmin,
} from "../../../../../app/common/helperContainer";
import {
  createImageFromInitials,
  globalSaveAnalytics,
  clearDefRoute,
  useCommonServices,
  useAdminServices,
  useDealerServices,
} from "../../../../../app/common/helperMethods";
import { useHistory } from "react-router-dom";
import { Form, Tooltip, OverlayTrigger } from "react-bootstrap";

export function UserProfileDropdown() {
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const { getDealersById } = useCommonServices();
  const { getDecLogURLWithNoReturn } = useAdminServices();
  const { getFormsDownload } = useDealerServices();
  const uiService = useHtmlClassService();
  const [uploadImg, setUploadImg] = useState("");
  const [, setIsdecisionURL] = useState(true);

  const postToAnalytics = async (pageURL, remarks) => {
    const role = user && user[1].role ? user[1].role : "";
    const userId = user && user[1].userId ? user[1].userId : "";
    const dealerId =
      user && user[1].assDealer.length > 0
        ? user[1].assDealer.filter((o) => o.isDefault === true)[0].dealerId
        : "";
    const dealerName =
      user && user[1].assDealer.length > 0
        ? user[1].assDealer.filter((o) => o.isDefault === true)[0].name
        : "";
    await globalSaveAnalytics(
      userId,
      role,
      pageURL,
      "0",
      dealerId,
      dealerName,
      remarks,
      user[1].email
    );
  };

  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const logoutClick = () => {
    clearDefRoute();
    clearAllStorages();
    postToAnalytics("/logout", "");
    history.push("/logout");
  };

  useEffect(() => {
    dealerData(user[1].assDealer);

    if (user[1].role === dealerEmployee || user[1].role === dealerAdmin)
      checkDecisionLogicURL();

    if (user && user[1].profileImage) {
      setUploadImg(imagePlaceHolder[0] + user[1].profileImage);
    } else {
      //here we are generating avatar
      const last = user[1].lastName ? user[1].lastName : "";
      const name = user[1].firstName + " " + last;

      const img = createImageFromInitials(500, name);
      setUploadImg(img);
      // setUploadImg(toAbsoluteUrl("/media/images/default.jpg"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateUser = (tab) => {
    if (enumUserTabs.Profile === tab) {
      setStorage(isProfile, true);
    } else {
      setStorage(isProfile, false);
    }

    setStorage(selectedUserId, user[1].userId);
    setStorage(userActiveTabKey, tab);
    setStorage(isEdit, true);
    clearStorage(analyticUsrMgt);
    setStorage(analyticUsrMgt, true);
    window.location.href = "/admin/userManagement";
  };

  const setDealerDetails = (dealerInfo) => {
    const data = {
      dealerId: dealerInfo.clientDealerId,
      dealerName: dealerInfo.dealerName,
      dealerAddress: dealerInfo.address,
      dealerLocation: `${dealerInfo.city} ${dealerInfo.stateCd} ${dealerInfo.postalCd}`,
    };
    clearStorage(dealerDetails);
    setStorage(dealerDetails, JSON.stringify(data));
  };

  const dealerData = async (dealerIds) => {
    if (dealerIds && dealerIds.length > 0) {
      const preDealer = getStorage(dealerDetails);
      if (preDealer === null) {
        if (dealerIds.lenght === 1) {
          const data = await getDealersById(dealerIds[0].dealerId);
          if (data && data?.length === 1) {
            setDealerDetails(data[0]);
          } else {
            const selData = data.filter(
              (res) => res.clientDealerId === res.primaryDealerId
            );
            setDealerDetails(selData[0]);
          }
        } else {
          var ids = "";
          dealerIds.forEach((element) => {
            ids += element.dealerId + ",";
          });
          const data = await getDealersById(ids);
          if (data?.length > 0) {
            const selData = data?.filter(
              (res) => res.clientDealerId === res.primaryDealerId
            );
            setDealerDetails(selData[0]);
          }
        }
      }
    }
  };

  // const handleDecisionLogicURL = async () => {
  //   const result = await getDecisionURL();

  //   if (result?.data !== "") {
  //     let splitData = result?.data.split("/");
  //     copy(result?.data.split("/")[splitData.length - 1]);
  //     window.open(result?.data, "_blank");
  //   }
  // };

  const checkDecisionLogicURL = async () => {
    const result = await getDecisionURL();

    if (result?.data && result?.data.status !== 404) {
      setIsdecisionURL(false);
    }
  };

  const getDecisionURL = async () => {
    return await getDecLogURLWithNoReturn(user[1].assDealer[0].dealerId);
  };

  const handleGlobalSearch = () => {
    var gSrhValue = { value };
    setStorage(globalSearchValue, gSrhValue.value);
    if (location.pathname.includes("allapps") === true) {
      window.location.href = "/dealer/allapps";
    } else {
      history.push("/dealer/allapps");
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  const [value, setValue] = useState();

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <Form>
          <div ref={ref} className={className} aria-labelledby={labeledBy}>
            <Form.Control
              autoFocus
              className="mx-3r my-2 w-auto txtgsearch"
              placeholder="Search"
              onChange={(e) => setValue(e.target.value)}
              value={value}
              id="txtgSearch"
              autoComplete="off"
            />

            <button
              id="btntopsearch"
              className="btn btntopsearch-info m-btn m-btn--icon m-btn--icon-only btn-sm m-btn--pill btntopsearch"
              onClick={() => handleGlobalSearch()}
            >
              <i
                className="flaticon2-search-1"
                style={{ marginTop: "7px" }}
              ></i>
            </button>
            <span>
              <button
                type="button"
                className="btn m-btn m-btn--icon m-btn--icon-only btn-sm m-btn--pill btntopsearchcancel"
                hidden
                // onClick={handleClosePopup}
              >
                <i className="la la-remove" style={{ fontSize: "large" }}></i>
              </button>
            </span>
          </div>
        </Form>
      );
    }
  );

  const clickFundingIcon = () => {
    postToAnalytics("/dealer/fundingDelay", "");
    history.push("/dealer/fundingDelay");
  };

  const handleHelpTraining = async () => {
    const helpTrainingName = "DealerPortalGuide1.pdf";
    const result = await getFormsDownload(helpTrainingName);
    if (result && result?.data !== null) {
      var byteCharacters = atob(result?.data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: "application/pdf;base64" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  return (
    <Dropdown drop="down">
      <Dropdown
        drop="down"
        style={{ marginTop: "20px" }}
        className="globalSearchTop"
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <span className="globalSearch">
            <i className="flaticon2-search-1" style={{ color: "white" }}></i>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          as={CustomMenu}
          className="dropdown-menuGSearch"
        ></Dropdown.Menu>
      </Dropdown>

      <div
        className="m-nav__item liFundingUpdate fundingUpdateM"
        id="fundingUpdate"
        style={{ display: "none", cursor: "pointer" }}
      >
        <div
          className="m-nav__link spnComments"
          onClick={() => clickFundingIcon()}
        >
          <i className="fa fa-exclamation-circle icnExclamation"></i>
          <span className="fundingUpdateClock">
            <i className="far fa-clock"></i>
          </span>
        </div>
      </div>

      {getStorage(dealerDetails) ? (
        <ul style={{ listStyleType: "none", padding: "3px" }}>
          <li className="whiteFontColor">
            {JSON.parse(getStorage(dealerDetails)).dealerName}
          </li>
          <li className="whiteFontColor">
            {JSON.parse(getStorage(dealerDetails)).dealerAddress}
          </li>
          <li className="whiteFontColor">
            {JSON.parse(getStorage(dealerDetails)).dealerLocation}
          </li>
        </ul>
      ) : (
        <div style={{ marginTop: "22px" }}>
          <span className="font-weight-bold font-size-base d-none d-md-inline mr-1 whiteFontColor">
            Hi
          </span>
          <span
            className="font-weight-bolder font-size-base d-none d-md-inline whiteFontColor"
            style={{ paddingRight: "0.5rem" }}
          >
            {user && user[1].firstName}
          </span>
        </div>
      )}

      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div className="col-lg-12 symbol pr-0 pl-0">
          <img
            alt="Pic"
            src={uploadImg}
            style={{ borderRadius: "25px", cursor: "pointer" }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        {!layoutProps.light && (
          <div
            className="d-flex align-items-center justify-content-between flex-wrap p-4 bgi-size-cover bgi-no-repeat rounded-top"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/images/user_profile_bg.jpg"
              )})`,
            }}
          >
            <div className="form-group row ml-0 marBot0px">
              <div className="profile_img symbol bg-white-o-15 pr-0 pl-0">
                <img
                  alt="Pic"
                  src={uploadImg}
                  style={{ borderRadius: "25px" }}
                />
              </div>
              <div className="profile_name text-white pr-0 pl-0">
                <ul
                  style={{
                    listStyleType: "none",
                    paddingInlineStart: "3px",
                    marginBlockEnd: "0",
                  }}
                >
                  <li className="font-size-h5">
                    {user[1].firstName + " " + user[1].lastName}
                  </li>
                  {user[1].email.length > 25 ? (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="profileEmail">{user[1].email}</Tooltip>
                      }
                    >
                      <li>{user[1].email.substring(0, 25) + "..."}</li>
                    </OverlayTrigger>
                  ) : (
                    <li>{user[1].email}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div className="navi navi-spacer-x-0 pt-5">
          <div
            className="navi-item px-8"
            style={{ cursor: "pointer" }}
            onClick={() => navigateUser(enumUserTabs.Profile)}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="m-nav__link-icon fa fa-user"></i>
              </div>
              <div className="navi-text">
                <div className="m-nav__link-text">My Profile</div>
              </div>
            </div>
          </div>

          {/* if User_Type =="Admin" only visible. */}
          {/* User management list is also visiable for veros employees, but action items are disabled */}
          {/* {user[1].role !== dealerEmployee && user[1].role !== verosEmployee && ( */}
          {user[1].role !== dealerEmployee && (
            <div
              style={{ cursor: "pointer" }}
              className="navi-item px-8"
              onClick={() => navigateUser(enumUserTabs.UserList)}
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="m-nav__link-icon fa fa-users"></i>
                </div>
                <div className="navi-text">
                  <div className="m-nav__link-text">User Management</div>
                </div>
              </div>
            </div>
          )}

          {/* if user is not veros one */}
          {/* {(user[1].role === dealerEmployee ||
            user[1].role === dealerAdmin) && (
            <div
              style={{ cursor: "pointer" }}
              className="navi-item px-8"
              onClick={handleDecisionLogicURL}
              hidden={isdecisionURL}
            >
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <img
                    alt=""
                    src="/media/images/DecisionLogic_grey.png"
                    width="20"
                  />
                </div>
                <div className="navi-text">
                  <div className="m-nav__link-text">Decision Logic URL</div>
                </div>
              </div>
            </div>
          )} */}

          <div
            className="navi-item px-8"
            style={{ cursor: "pointer" }}
            onClick={handleHelpTraining}
          >
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="m-nav__link-icon far fa-question-circle"></i>
              </div>
              <div className="navi-text">
                <div className="m-nav__link-text">Training Help</div>
              </div>
            </div>
          </div>

          <div className="navi-footer  px-8 py-5">
            <div
              onClick={logoutClick}
              className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder btn-logout"
            >
              Log Out
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
