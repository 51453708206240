export const onPremEnv = process.env.REACT_APP_IS_ONPREM || "true";

export const apiGateway =
  onPremEnv === "true"
    ? process.env.REACT_APP_ONPREM_GATEWAY_URL || "http://localhost:7010"
    : process.env.REACT_APP_AZURE_GATEWAY_URL ||
      "https://deallaneqa.veroscredit.com";

export const googleReSiteKey =
  process.env.GOOGLE_SITE_KEY || "6LfJBispAAAAAC4a75_b8ZyngXFN8T4O6XJQPk4K";
