import { jwtDecode } from "jwt-decode";
import {
  dataTblPgLen,
  dwat,
  enumContentTypes,
} from "../../../common/helperContainer";
import { globalSaveAnalytics } from "../../../common/helperMethods";
import { apiGateway } from "../../../common/apiURLs";
import { setStorage, getStorage } from "../../../common/storageHelper";
import { post, getWithAuth, winToken, regularPost } from "./authAxios";
export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgotpassword";
export const ME_URL = "api/me";

export async function login(
  email,
  password,
  browserId,
  platform,
  os,
  osVersion,
  browser,
  browserVersion,
  rememberMe,
  mfaType,
  grcT
) {
  const baseUrl = apiGateway;
  const queryUrl = `/umi/Token`;
  const data = `email=${email}&password=${password}&browserId=${browserId}&platform=${platform}&os=${os}&osVersion=${osVersion}&browser=${browser}&browserVersion=${browserVersion}&rememberMe=${rememberMe}&mfaType=${mfaType}&grcT=${grcT}&grant_type=password`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
}

export async function verifyMfaOtp(
  email,
  password,
  browserId,
  mfaOtp,
  rememberMe,
  queryUrl
) {
  const baseUrl = apiGateway;
  const data = `email=${email}&password=${password}&browserId=${browserId}&mfaOtp=${mfaOtp}&rememberMe=${rememberMe}&grant_type=password`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
}

export const cancelTempMobile = async (
  mobileNumber,
  emailId,
  browserId,
  reqMobileNotification,
  password
) => {
  const queryUrl = `/umi/cancelmobileadd`;
  const baseUrl = apiGateway;
  const data = `mobileNumber=${mobileNumber}&emailId=${emailId}&browserId=${browserId}&reqMobileNotification=${reqMobileNotification}&password=${password}`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
};

export const saveTempMobile = async (
  mobileNumber,
  emailId,
  browserId,
  reqMobileNotification,
  password,
  bothOptions
) => {
  const queryUrl = `/umi/tempmobile`;
  const baseUrl = apiGateway;
  const data = `mobileNumber=${mobileNumber}&emailId=${emailId}&browserId=${browserId}&reqMobileNotification=${reqMobileNotification}&password=${password}&bothOptions=${bothOptions}`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
};

export async function winLogin(emailId) {
  const baseUrl = apiGateway;
  const queryUrl = `/umi/WinToken`;
  const data = `email=${emailId}&grant_type=active_directory`;
  const response = await winToken(baseUrl, queryUrl, data);
  return response;
}

// export function register(email, fullname, username, password) {
//   return axios.post(REGISTER_URL, { email, fullname, username, password });
// }

// export function requestPassword(email) {
//   return axios.post(REQUEST_PASSWORD_URL, { email });
// }

export async function getUserByToken() {
  const authToken = getStorage(dwat);
  var decoded = jwtDecode(authToken);
  const baseUrl = apiGateway;
  const queryUrl = `/umi/api/user/${decoded.jti}`;
  const response = await getWithAuth(baseUrl, queryUrl);
  if (response.data) {
    const json = response.data;
    const defUser = {
      userId: decoded.jti,
      role: json.role.name,
      assDealer: json.associatedDealers,
      firstName: json.firstName,
      lastName: json.lastName,
      userType: json.userType,
      profileImage: json.profileImage,
      userName: json.userName,
      email: json.email,
      isRehashUser: json.isRehashUser,
      isAppEntryUser: json.isAppEntryUser,
      isCustEditUser: json.isCustEditUser,
      isEmpHierarchy: json.isEmpHierarchy,
      menuTabPermission: json.menuTabPermission,
      isContract: json.isContract,
    };

    // Setting Default page length property
    const defVal = getStorage(dataTblPgLen);
    if (defVal === null) {
      setStorage(dataTblPgLen, json.pageLength);
    }

    if (defUser) {
      saveAnalytics(defUser);
      return [200, { ...defUser }];
    }
  } else return [""];
}

const saveAnalytics = async (defUser) => {
  const role = defUser.role;
  const userId = defUser.userId;
  const dealerId =
    defUser.assDealer && defUser.assDealer.length > 0
      ? defUser.assDealer.filter((o) => o.isDefault === true)[0].dealerId
      : "";
  const dealerName =
    defUser.assDealer && defUser.assDealer.length > 0
      ? defUser.assDealer.filter((o) => o.isDefault === true)[0].name
      : "";
  await globalSaveAnalytics(
    userId,
    role,
    "/auth/login",
    "0",
    dealerId,
    dealerName,
    "",
    defUser.email
  );
  await globalSaveAnalytics(
    userId,
    role,
    "/dealer/allapps",
    "0",
    dealerId,
    dealerName,
    "",
    defUser.email
  );
};

export async function forgetPassword(userName, grToken) {
  const baseUrl = apiGateway;
  const queryUrl = `/umi/ForgotPassword`;
  const param = {
    userName: userName,
    grToken: grToken,
  };
  const response = await regularPost(
    baseUrl,
    queryUrl,
    param,
    enumContentTypes.json
  );
  return response;
}

export async function validateUser(userId, token) {
  const baseUrl = apiGateway;
  const queryUrl = `/umi/newUserConfirmEmail?userId=${userId}&token=${token}`;
  const response = await post(baseUrl, queryUrl, "");
  return response;
}

export async function resetForgottenPassword(password, token) {
  const baseUrl = apiGateway;
  const data = `password=${password}&tokenId=${token}`;
  const queryUrl = `/umi/resetForgottenPassword`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
}

export async function sendMFA(userName, browserId, mfaType) {
  const baseUrl = apiGateway;
  const data = `email=${userName}&browserId=${browserId}&mfaType=${mfaType}`;
  const queryUrl = `/umi/user/sendmfanotification`;
  const response = await post(baseUrl, queryUrl, data);
  return response;
}
